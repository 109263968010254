.teamContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .headingContainer {
    margin-bottom: 20px; 
  }
  
  .h2 {
    font-size: 80px;
  }

  .subheading {
    font-size: 40px; 
    margin-top: 10px;
  }
  
  .teamImageContainer {
    position: relative;
  }
  
  .teamImage {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

.caption {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 5px;
    color: rgb(145, 9, 9);
  }
