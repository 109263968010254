.aboutDesktop {
    display: flex;
    justify-content: center;
  }
  
  .aboutMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .aboutContent {
    flex: 2;
    max-width: 100%;
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;
    margin: 15vh;
    position: relative; 
  }
