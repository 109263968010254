

.searchContainer{
    padding-top: 20vh;
    margin: auto;
    width: 40%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .searchbar{
    background-color: white;
    width: 90vw;
    border-radius: 10px;
    height: 2.5rem;
    padding: 0 15px;
    box-shadow: 0px 0px 8px #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4vw;
  }

  .searchbar input{
    font-family: "Nunito";
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: calc(1.0*(1vh + 1vw));
    width: 100%;
    margin-left: 1vh;
  }

  .searchbar input:focus{
    outline: none;
  }


.searchlist {
    width: 105%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
}