.navbtn {
    font-family: "Nunito", sans-serif;
    padding: 2vh 4vh;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(.75*(1vh + 1vw));
    font-weight: 500;
    float: right;
    margin-left: 2vh;
    background-color: rgba(153,0,0,.1);
    color:black;
    transition: background 700ms;
    text-decoration: None;
}

.navbtn:hover{
    background-color: rgba(255,255,255,.6);
    color: rgba(153,0,0,1);
  }