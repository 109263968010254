 *{
    font-family: "Big Caslon", serif;
 }
   
  
  .container {
    width: 88%;
    height: 100%;
    background: #fffff9;
    padding-left: 6%;
    padding-top: 2%;
  }

  
  .Nav { 
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding-right: 16vh;
  }
  
  .virtuoso {
    color: #080808;
    text-align: left;
    font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", serif;
    font-size: calc(1.9*(1vh + 1vw));
    line-height: 200%;
    position: relative;
    margin-left: -8vh;
    z-index: 2;
   
  }


  .btn {
    font-family: "Nunito", sans-serif;
    padding: 12px 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    float: right;
    margin-left: 10px;
    background-color: rgba(153,0,0,.1);
    color:black;
    transition: background 700ms;
    text-decoration: None;
  }

  .btn:hover{
    background-color: rgba(255,255,255,.6);
    color: rgba(153,0,0,1);
  }

  .userbtn{
    font-family: "Nunito", sans-serif;
    padding: .75vh 3.85vh;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(.5*(1vh + 1vw));
    font-weight: 600;
    float: right;
    margin-left: 2vh;
    background-color: rgba(255,255,255,.1);
    color: black;
    transition: background 700ms;
    text-decoration: None;
    margin-bottom: .75vh;
  }

  .userbtn:hover{
    background-color: rgba(153,0,0,.1);
    color: rgba(153,0,0,1);
    text-decoration: underline;
  }
  
  .userbtn2{
    font-family: "Nunito", sans-serif;
    padding: .75vh 3vh;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: calc(.5*(1vh + 1vw));
    font-weight: 600;
    float: right;
    margin-left: 2vh;
    background-color: rgba(255,255,255,.1);
    color: black;
    transition: background 700ms;
    text-decoration: None;
  }

  .userbtn2:hover{
    background-color: rgba(153,0,0,.1);
    color: rgba(153,0,0,1);
    text-decoration: underline;
  }

  .userlist{
    list-style-type: none;
  }
  


  .search-item {
    text-align: right;
    line-height: 150%;
    position: relative;
  }

  .header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    position: fixed;
    height: 9vh;
    top:0;
    left:0;
    width: 100%;
    padding: 20px 100px;
    background:rgba(255,255,255,.9);
    z-index: 100;
    margin-bottom: 9vh;
  }

  .searchContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    
  }