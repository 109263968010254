.dropdown{
    position: absolute;
    top: 12vh;
    width: 15vh;
    transform: translateX(-45%);
    background-color: rgba(255,255,255,.9);
    border: none;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0px 0px 5px rgba(0,0,0,.1);
    overflow: hidden;
    transition: height 1s ease;
}


.item{
    text-decoration: none;
    font-weight: 525;
    font-family: "Poppins", sans-serif;
    font-size: calc(.75*(1vh + 1vw));;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem;
    color: black;
    transition: background 700ms;
}

.item:hover{
    color: rgba(153, 0, 0, 1);
    background-color: rgba(153, 0, 0, .1);
}

.menu{
    width: 100%;
}

.menu li{
    list-style-type: none;
  }

.menu a{
    text-decoration: none;
}

.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 700ms ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all 700ms ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 700ms ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all 700ms ease;
  }