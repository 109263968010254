.art-container {
    width: 90%;
    margin: auto;
  }
  
  .flex-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  
  .image-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
  }
  .btns {
    text-align: center;
    padding-top: 10px;
  }
  
  .button-style {
    font-size: 20px;
    color: gray;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 8px;
  }
  
  .txt-contain {
    margin: 20px;
    position: relative;
    width: 50%;
  }
  
  @media (max-width: 768px) {
    .art-container {
        width: 90%;
        margin: auto;
      }

    .flex-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      
      .image-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 90%;

      }

      .txt-contain {
        margin: 20px;
        position: relative;
        width: 90%;
      }
  }