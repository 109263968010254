.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}


.userCard {
  width: 20vw;
  height: 20vw;
  overflow: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profilePhoto {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  margin-top: 1vh;
  font-size: 16px;
}


@media (max-width: 768px) {
  .items {
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw; 
  }
}
@media (max-width: 768px) {
  .userCard {
    width: 40vw;
    height: 40vw;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .profilePhoto {
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    object-fit: cover;
  }
  

}