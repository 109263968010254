/* just made this page for later refactoring */
.artTextColsContainer {
    width: 90%;
    margin: auto;
  }
  .link {
    color: gray; 
    text-decoration: none; 
  }
  
  .link:hover {
    color: black; 
    text-decoration: underline;
  }