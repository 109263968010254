footer{
    background: rgba(153, 0, 0, 0.1);
    width: 100%;
  }
  footer .content{
    max-width: 1250px;
    margin: 0 auto;
    padding: 30px 40px 40px 40px;
  }
  footer .content .top{
    margin-bottom: 10px;
  }
  .content .boxes .box {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
  }
  footer .content .boxes{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  footer .content .boxes .box{
    width: 60%;
    flex-wrap: wrap;
  }
  .content .boxes .box li{
    margin: 6px 0;
    list-style: none;
  }
  .content .boxes .box li a{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease
  }
  .content .boxes .box li a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  .content .boxes .input-box{
    margin-right: 55px;
    flex-wrap: wrap;
  }

  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
    text-decoration: none;

  }
  .bottom-details .bottom_text a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  .bottom-details .bottom_text a{
    margin-right: 10px;

  }

  /*responsiveness*/
  @media (max-width: 700px) {
    footer .content .boxes{
      flex-wrap: wrap;
    }
    .box{
      width: 80%;
      margin-top: 10px;
    }
    footer .content .boxes .input-box{
      width: 40%;
      margin-top: 10px;
    }
  }

  ul li input {
    width: 175px;
    border-color: black;
  }
.txt {
    color: rgb(0, 0, 0);
    margin-right: 2vw;
    text-decoration: none;

  }