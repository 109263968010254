.p {
  margin-top: 0.3333333333rem;
  font-size: 1.0666666667rem;
  position: relative;
  color: solid black;
}
.p::after {
  content: "*";
  color: red;
  margin-left: 6px;
  vertical-align: super;
}
.h1 {
  text-align: center;
  font-size: 28px;
  padding-bottom: 5px;
  color: solid black;
}
.h2 {
  margin-top: 0.3333333333rem;
  margin-bottom: 2.3333333333rem;
  font-size: 1.0666666667rem;
  text-align: center;
  color: gray;
}
.screen_container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;
}
.small_container {
  width: 35%;
  height: 70%;
  background-color: #f5f5f5;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.inputbox,
.btn {
  width: 70%;
  background-color: #506bec;
  height: 40px;
  margin: 10px 0;
  padding: 7px;
  border: 2px solid black;
  border-radius: 12px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input {
  width: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  outline: none;
}

.btn {
  background-color: gray;
  color: #ffffff;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background 800ms ease;
}

.btn:hover {
  background-color: red;
  color: #ffffff;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.flexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btnInline {
  padding: 7px 15px;
  background-color: #506bec;
  color: #ffffff;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  transition: background-color 300ms ease;
}

.btnInline:hover {
  background-color: red;
  color: #ffffff;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  margin: 0 auto;
}
.pLabel {
  margin-top: 10px;
  margin-bottom: 5px;
  color: gray;
  font-size: 16px;
  text-align: left;
}
.inputbox {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 7px;
  border: 2px solid black;
  border-radius: 12px;
  background: transparent;
}

.btn {
  font-family: "Poppins", sans-serif;
  background-color: #506bec;
  font-size: 18px;
  padding: 12px 32px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
}
.logo {
  width: 100px; 
  height: auto;
  margin-bottom: 15px; 
}