.profilephoto {
    width: 6.5vh;
    height: 6.5vh;
    border: 5px solid transparent;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: space-betwe;
    align-items: center;
    margin-left: 3vh;
    z-index: 1;
    transition: border 1200ms;
  }
  
  .profilephoto:hover {
    border: 5px solid rgba(153, 0, 0, .7);
  }
  
  
  .profilephoto img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }