* {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.main{
  width: 100%;
  height: 100vh; 
}

.videobg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(255,255,255,.3);
}

.container {
  padding: 5vh 12vh;
  background-color: rgba(255,255,255,.2);
  border: none;
  border-radius: 20px;
  max-width: 80vw;
}

.header {
  font-size: 28px;
  text-align: center;
  font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", serif;
  margin: 5vh;
}

.header a{
  text-decoration: none;
}

.h2 {
  color: #990000;
}

.h1 {
  color: Black;
}

.inputbox {
  position: relative;
  width: 300px;
  height: 40px;
  margin: 1vh 0;
  padding: 7px;
  border: none;
  outline: none;
  border: 2px solid black;
  border-radius: 12px;
  background: transparent;
  max-width: 80vw;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.input:focus{
  outline: none;
}

.input::placeholder {
  color: black;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #990000;
}

.forgot {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-size: 14.5px;
  margin: -15px 0 15px;
  color: black;
}

.in {
  font-family: "Poppins", sans-serif;
  accent-color: black;
  margin-right: 3px;
}

.a {
  font-family: "Poppins", sans-serif;
  color: black;
  text-decoration: none;
}

.a:hover {
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
}
.btn {
  margin: 2vh 0;
  width: 100%;
  height: 7vh;
  background: rgba(255,255,255,.2);
  border: none;
  outline: none;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: black;
  font-weight: 800;
  transition: background 800ms ease;
}

.btn:hover {
  background: rgba(255,255,255,.5);
  color: #990000;
}

.register {
  font-family: "Poppins", sans-serif;
  font-size: 14.5px;
  text-align: center;
  margin-top: 20px;
  color:black;
}

.a2 {
  font-family: "Poppins", sans-serif;
  color: black;
  text-decoration: none;
  font-weight: 800;
}

.a2:hover {
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
}

.p{
  font-family: "Poppins", sans-serif;
}

.inputContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.center {
  position: relative;
  height: 40px;
  margin: 1vh 0;
  padding: 7px;
  background: transparent;
  border: none;
  outline: none;
  border: 2px solid black;
  border-radius: 12px;
}
 
@media (max-width: 720px) {
  .container {
    display: flex;
    background-color: rgba(255,255,255,.2);
    border: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  }
  
}