.p {
  margin-top: 0.3333333333rem;
  font-size: 1.3666666667rem;
  position: relative;
  color: black;
}
.p::after {
  content: "*";
  color: red;
  margin-left: 6px;
  vertical-align: super;
}
.h1 {
  text-align: center;
  font-size: 35px;
  padding-bottom: 5px;
  color: black;
}

.screen_container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;
}

.small_container {
  width: 35%;
  height: 70%;
  background-color: #f5f5f5;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.input {
  width: 100%;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 500;
  color: gray;
  outline: none;
  border: 2px solid black;
  padding: 10px;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  margin: 0 auto;
  padding: 10px;
}

.btn {
  font-family: "Poppins", sans-serif;
  width: 70%;
  background-color: #506bec;
  color: white;
  font-size: 18px;
  padding: 12px 32px;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-radius: 50px;
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 15px;
}
.toggle {
  font-size: 16px;
  color: black;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.toggle input {
  margin-right: 10px;
}
