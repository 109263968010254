.image-card-container {
  position: relative;
  display: inline-block; 
  width: 100%;
}
.image-card {
    width: 20vw;
    height: 20vw;
    overflow: hidden;
    margin: auto;
  }
  
  .image-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.image-card {
    position: relative;
}

.hoverContent {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(177, 66, 66, 0.553); 
    padding: 10px;
    color: white; 
    font-size: 16px; 
    object-fit: cover;
    right: 0; 
    bottom: 0;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.image-card:hover .hoverContent {
    display: block;
}


@media(max-width: 768px){
  .hoverContent {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(177, 66, 66, 0.553); 
    padding: 10px;
    color: white; 
    font-size: 12px; 
    object-fit: cover;
    right: 0; 
    bottom: 0;
    display: flex;
    align-items: center; 
    justify-content: center;
}
  .image-card {
    width: 40vw;
    height: 40vw;
    overflow: hidden;
  }
}

