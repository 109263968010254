button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 30px;
  }
  .on {
    color: #000000;
  }
  .off {
    color: #a3a3a373;
  }