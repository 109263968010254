.review-card {
    flex: 1;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 45%;
    padding: 20px;
    position: relative; 
  }
  .review-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vh;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }


  .help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50%; 
    font-size: 24px;
    font-family: Poppins;
    background-color: #e8e8e8;
    color: rgb(134, 131, 131);
    border: 2px solid rgb(134, 131, 131);
    cursor: pointer;
    z-index: 1001; 
  }
  
  .popup {
    position: fixed;
    bottom: 80px; 
    right: 20px;
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    padding: 10px;
    z-index: 1000;
  }
  
  .popup-inner {
    text-align: center;
  }